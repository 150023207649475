import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { Bank, LoginButton, Logo, Providers, RegisterButton, Slotgames } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { AppContext } from "src/main/constants";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => () => {
      window.open(type === "login" ? loginUrl : registerUrl, "_blank");
    },
    [loginUrl, registerUrl],
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Xo268</VisuallyHidden>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={1.2}
      >
        <Box>
          <Typography
            align="center"
            color="#fb0303"
            fontSize={22}
            fontWeight={900}
            sx={{
              WebkitTextStroke: "1px #fff",
            }}
          >
            เว็บเกมชั้นนำ
          </Typography>
          <Typography
            align="center"
            color="#fff"
            fontSize={35}
            fontWeight={900}
            sx={{
              marginTop: "-10px",
              WebkitTextStroke: "1px #000",
            }}
          >
            ค่ายใหญ่แจกหนัก
          </Typography>
        </Box>
        <img
          alt="logo"
          src={Logo}
          width={250}
          height={84}
        />
        <Typography
          align="center"
          color="#fefdfd"
          fontSize={19}
          fontWeight={900}
          sx={{
            marginTop: "-10px",
            WebkitTextStroke: "1px #000",
          }}
        >
          ระบบออโต้ ใช้งานง่าย รองรับทุกอุปกรณ์
        </Typography>
        <Stack
          direction="row"
          spacing={1}
        >
          <img
            alt="login"
            src={LoginButton}
            width={160}
            height={47}
            style={{ cursor: "pointer" }}
            onClick={handleClick("login")}
          />
          <img
            alt="register"
            src={RegisterButton}
            width={160}
            height={47}
            style={{ cursor: "pointer" }}
            onClick={handleClick("register")}
          />
        </Stack>
      </Stack>

      <Box
        sx={styles.images}
        mt="10px"
      >
        <img
          alt="hero backdrop"
          src={Slotgames}
          width={360}
          height={375}
        />
      </Box>

      <Stack
        marginTop="-10px"
        alignItems="center"
        spacing="10px"
      >
        <img
          alt="logo"
          src={Logo}
          width={180}
          height={61}
        />
        <Typography
          align="center"
          color="#fefefd"
          px="12px"
          fontSize={10}
          lineHeight={1.3}
          fontWeight={500}
        >
          สนุกสนานตื่นเต้นไปกับการเล่นเกมสล็อตกับเรา XO268 ที่นี่! XO268
          เป็นแพลตฟอร์มที่น่าสนใจสำหรับคนรักเกมสล็อตออนไลน์ XO268 มีระบบที่ล้ำสมัยและเล่นง่าย แอพ XO268
          ของเรารองรับทุกอุปกรณ์ XO268 ระบบฝากถอนอัตโนมัติ รวดเร็วทันใจ ไม่ต้องรอนาน นอกจากนี้ XO268 ยังมีโบนัสใหญ่ๆ
          และโอกาสในการชนะที่น่าตื่นเต้นรอคุณอยู่ XO268 ทุกครั้งที่คุณกดสปินรางวัลใหญ่อาจเป็นของคุณ ที่ XO268
          คุณจะได้พบกับหลากหลายเกมสล็อตที่มีธีมภาพสวยงามพร้อมเสียงเพลงที่สนุกสนาน ที่ช่วยเพิ่มประสบการณ์การเล่นของคุณ
          XO268 ไม่ว่าคุณจะเป็นนักพนันระดับเซียนหรือมือใหม่ที่กำลังมองหาความบันเทิงใหม่ๆ ที่ XO268 คุณจะพบกับความสนุกและ
          ความตื่นเต้นที่ไม่มีวันหมดสิ้น XO268 เรามีค่ายเกมชั้นนำมากมายให้คุณเลือกเล่น อาทิเช่น
        </Typography>
        <img
          alt="providers"
          src={Providers}
          width="85%"
        />
        <Typography
          align="center"
          color="#fefefd"
          px="12px"
          fontSize={10}
          lineHeight={1.3}
          fontWeight={500}
        >
          XO268 เป็นเว็บตรง ไม่ผ่านเอเยนต์ ไม่ล็อคยูส XO268 ให้บริการครบวงจร 24 ชั่วโมง XO268 อัปเดตเกมใหม่ๆ อยู่เสมอ
          ทำให้เว็บไซต์ XO268 แห่งนี้ได้กลายเป็นตัวเลือกอันดับต้นๆ ของผู้เล่นมากมาย XO268 โบนัสแตกง่าย
          แตกเท่าไหร่เราก็จ่าย ไม่มีกั๊ก XO268 เต็มไปด้วยความบันเทิงเหนือชั้น
          พร้อมโปรโมชั่นสุดพิเศษมากมายที่ให้ผลตอบแทนยอดเยี่ยมกับลูกค้าคนสำคัญของ XO268 ลองเข้ามาเล่น XO268
          และคุณจะรู้สึกได้เองว่าทำไม XO268 ถึงเป็นเว็บไซต์ที่คนนิยมในตอนนี้!
        </Typography>
        <img
          alt="bank"
          src={Bank}
          width="100%"
        />
      </Stack>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    pb: "110px",
    "@media (min-width: 800px)": {
      pb: "20px",
    },
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "365px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
  },
});

export default LandingPage;
