import { Box, BoxProps } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { FloatingBottomBanner } from "src/assets";
import { AppContext } from "src/main/constants";
import { createStyles } from "src/main/utils";

interface FloatingBannerProps extends BoxProps {}

const FloatingBanner: React.FC<FloatingBannerProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { floatingBannerUrl } = urls ?? {};

  const handleClick = useCallback(() => {
    window.open(floatingBannerUrl, "_blank");
  }, [floatingBannerUrl]);

  return (
    <Box sx={styles.root}>
      <img
        src={FloatingBottomBanner}
        alt="floating-banner"
        onClick={handleClick}
        width={100}
        height={100}
      />
    </Box>
  );
};

const styles = createStyles({
  root: {
    position: "fixed",
    bottom: 10,
    right: 10,
    width: "100%",
    zIndex: 15,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    img: {
      maxWidth: "100px",
      width: "100%",
      cursor: "pointer",
    },
  },
});

export default FloatingBanner;
