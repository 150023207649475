import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const BTN_LOGIN_URL = "https://a.ntrk2.com/7822c950-f2f4-4b5d-85de-4baeab78d783";
export const BTN_REGISTER_URL = "https://a.ntrk2.com/1e3df222-4f87-4b04-84c7-d00b451a4d64";

export const FLOATING_BANNER_URL = "https://xo268th.com/cs";

export * from "./codes";
export * from "./context";
